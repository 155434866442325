<template>
    <div class="homes-healthy">
        <div class="homes-healthy-cover cover container-fluid px-0 mt-5">
            <div class="container">
                <div class="row mx-2">
                    <div class="col-12 col-lg-6 cover-texts-bg my-5 md:ml-5">
                        <h4 class="text-white mx-5 mt-5 mb-4">Здравословно</h4>
                        <p class="text-white mx-5 my-4">
                            Основни причинители на алергии в дома са акари, плесени, цигарен дим, както и натрупани замърсявания в матраците, меката мебел и килимите. Услугата е предназначена за семейства с деца и хора с алергии.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container mt-5 mb-3">
            <p>Грижата за <strong>чистота</strong> е грижа за <strong>здравето</strong>.<br /></p>
            <h3>Здравословно почистване</h3>
            <p>Основни причинители на алергии в дома са акарите и натрупаните замърсявания в меката мебел и матраците. Те произвеждат <strong>алергени</strong>, които с времето предизвикват здравословни проблеми. Причинител на алергии също такa е домашният прах, който е навсякъде около нас - в тапетите, климатика, библиотеката, рамки на картини, шкафове, осветителни тела и др. Проблемът е още по-сериозен на места с висока влажност, през зимните месеци и при недостатъчна вентилация.&nbsp;<br />
			<br />
			<strong>Здравословното почистване</strong> включва отстраняване на всички тези отлагания и предизвикващ алергии прах. Целта е да се създаде <strong>чиста и здравословна среда</strong> за отглеждане на вашите деца.<br />
			<br />
			<strong>Матраците</strong> са най-силно замърсени с акари, бактерии, прах, гъбични спори и вируси поради трудността от вътрешно почистване. <strong>Меката мебел</strong> съдържа най-голямо количество акари - до 1000 от тях могат да живеят само на 1 м&sup2;. На всеки квадратен метър <strong>килим</strong>, който не е пран от 3 месеца, се събират около 6000 бактерии. Това е с 4000 повече от тези в тоалетната чиния. <strong>Климатиците</strong> също имат нужда от почистване на всеки 3 месеца.<br />
			<br />
			Целта на услугата <strong>Здравословно почистване</strong> е да се изчистят всички места с концентрация на алергени, за да оставим <strong>чист и здравословен дома</strong> Ви. Разполагаме с технология, която изпира и изтупва тези замърсявания, също така отстранява акарите. Препаратите, които използваме, са съобразени с <strong>екологичните</strong> стандарти, щадят околната среда и са безвредни за домашни любимци и растения.<br />
			<br />
			Услугата е предназначена за семейства с деца и хора с алергии.</p>
            <div class="bagdes container my-5">
                <div class="row">
                    <div class="col mb-5 mb-md-0">
                        <span class="d-flex bump"
                        @click="serviceActive = true, service2Active = false, service3Active = false, service4Active = false">
                            <span class="d-flex align-items-center">
                                <svg id="Capa_1" enable-background="new 0 0 510 510" height="75" viewBox="0 0 510 510" width="75" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m435.312 74.688c-48.163-48.164-112.199-74.688-180.312-74.688s-132.149 26.524-180.312 74.688c-48.164 48.163-74.688 112.199-74.688 180.312s26.524 132.149 74.688 180.313c48.163 48.163 112.199 74.687 180.312 74.687s132.149-26.524 180.312-74.688c48.164-48.163 74.688-112.199 74.688-180.312s-26.524-132.149-74.688-180.312zm-180.312-44.688c124.065 0 225 100.935 225 225 0 40.203-10.609 77.97-29.159 110.667l-98.518-56.837c1.349-7.765 2.197-15.734 2.521-23.83h20.854l36.352 22.72 15.9-25.439-43.648-27.281h-29.302v-25.375l35-20.192v-74.433h-30v57.106l-30 17.307v-14.947c0-32.11-25.585-58.339-57.437-59.414 6.177-17.486 22.863-30.052 42.437-30.052v-30c-24.508 0-46.305 11.818-60 30.052-13.695-18.234-35.492-30.052-60-30.052v30c19.574 0 36.26 12.566 42.437 30.052-31.852 1.074-57.437 27.304-57.437 59.414v14.947l-2.438-1.407-27.562-15.901v-57.105h-30v39.798l-58.825-33.938c39.188-66.294 111.394-110.86 193.825-110.86zm15.535 135c16.247 0 29.465 13.219 29.465 29.466v20.534h-90v-20.534c0-16.247 13.218-29.466 29.465-29.466zm53.725 127.64-82.576-47.64h83.316v32.5c0 5.117-.258 10.17-.74 15.14zm-139.26-15.14v-30.567l132.833 76.634c-3.672 11.088-8.701 21.268-14.969 30.115-13.163 18.584-30.162 28.818-47.864 28.818s-34.701-10.234-47.864-28.817c-14.275-20.153-22.136-47.208-22.136-76.183zm70 202.5c-124.065 0-225-100.935-225-225 0-30.947 6.282-60.454 17.636-87.316l107.364 61.941v25.375h-29.302l-43.648 27.28 15.9 25.439 36.352-22.719h20.854c.342 8.544 1.265 16.947 2.747 25.117l-37.903 21.057v58.826h30v-41.174l16.155-8.975c4.326 11.295 9.857 21.792 16.501 31.172 18.945 26.747 44.637 41.477 72.344 41.477s53.399-14.73 72.344-41.477c6.644-9.38 12.175-19.877 16.501-31.172l16.155 8.975v41.174h30v-24.798l44.302 25.559c-41.124 54.18-106.192 89.239-179.302 89.239z"/>
                                </svg>
                            </span>
                            <span class="d-flex align-items-center pl-3">
                                <span class="d-block badges-title">Отстраняване на Акарии</span>
                            </span>
                        </span>
                    </div>
                    <div class="col mb-5 mb-md-0">
                        <span class="d-flex bump"
                        @click="service2Active = true, serviceActive = false, service3Active = false, service4Active = false">
                            <span class="d-flex align-items-center">
                                <svg height="75" width="75" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	                            viewBox="0 0 496.176 496.176" style="enable-background:new 0 0 496.176 496.176;" xml:space="preserve">
                                    <rect x="440.057" y="440" width="16" height="16"/>
                                    <rect x="280.057" y="448" width="16" height="16"/>
                                    <path d="M495.491,440.337c-1.113-6.729-3.647-13.144-7.434-18.817V280c-0.058-36.212-22.275-68.699-56-81.888V168
                                        c0-4.418-3.582-8-8-8h-44.688l24-24h52.688c2.122,0,4.156-0.844,5.656-2.344l32-32c1.5-1.5,2.344-3.534,2.344-5.656V56
                                        c0-2.122-0.844-4.156-2.344-5.656l-32-32c-1.5-1.5-3.534-2.344-5.656-2.344h-32V8c0-4.418-3.582-8-8-8h-112
                                        c-2.122,0-4.156,0.844-5.656,2.344l-64,64L76.217,224.528l-45.48,7.584v0.136c-1.638,0.221-3.164,0.955-4.36,2.096l-24,24
                                        c-3.123,3.124-3.123,8.188,0,11.312l72,72c3.124,3.123,8.188,3.123,11.312,0l24-24c1.146-1.196,1.88-2.726,2.096-4.368h0.136
                                        l7.584-45.472l158.208-158.16L339.369,48h76.688c4.418,0,8-3.582,8-8v-8h28.688l27.312,27.312v33.376L452.745,120h-52.688
                                        c-2.122,0-4.156,0.844-5.656,2.344L356.745,160h-44.688c-4.418,0-8,3.582-8,8v30.112c-33.725,13.189-55.941,45.676-56,81.888v176
                                        c0,22.091,17.909,40,40,40c22.091,0,40-17.909,40-40h72.72c4.325,26.154,29.034,43.85,55.188,39.525
                                        C482.12,491.2,499.816,466.491,495.491,440.337z M80.057,324.688L19.369,264l12.688-12.688L92.745,312L80.057,324.688z
                                        M106.401,258.344c-1.179,1.18-1.959,2.699-2.232,4.344l-5.448,32.664l-50.016-50.016l32.672-5.448
                                        c1.642-0.275,3.158-1.055,4.336-2.232L240.057,83.312L260.745,104L106.401,258.344z M272.057,92.688L251.369,72l52.688-52.688
                                        L324.745,40L272.057,92.688z M408.057,32h-68.688l-16-16h84.688V32z M320.057,176h96v17.528c-5.275-0.997-10.631-1.508-16-1.528
                                        h-64c-5.369,0.02-10.724,0.531-16,1.528V176z M336.057,208h64c36.653,0.044,67.44,27.579,71.56,64h-207.12
                                        C268.616,235.579,299.404,208.044,336.057,208z M264.057,288h208v16h-208V288z M472.057,320v32h-48v-32H472.057z M264.057,320
                                        h144v16h-144V320z M264.057,352h144v8c0,4.418,3.582,8,8,8h56v38.496c-4.944-2.868-10.364-4.824-16-5.776V392
                                        c0-4.418-3.582-8-8-8h-160c-4.418,0-8,3.582-8,8v24.8c-5.821,1.234-11.29,3.763-16,7.4V352z M288.057,480
                                        c-13.255,0-24-10.745-24-24s10.745-24,24-24s24,10.745,24,24S301.312,480,288.057,480z M400.777,440h-76.112
                                        c-5.212-11.913-15.875-20.561-28.608-23.2V400h144v0.72C419.957,404.155,404.212,419.9,400.777,440z M448.057,480
                                        c-17.673,0-32-14.327-32-32c0-17.673,14.327-32,32-32c17.673,0,32,14.327,32,32C480.057,465.673,465.73,480,448.057,480z"/>
                                    <path d="M48.057,344c0-13.255-10.745-24-24-24s-24,10.745-24,24s10.745,24,24,24S48.057,357.255,48.057,344z M16.057,344
                                        c0-4.418,3.582-8,8-8s8,3.582,8,8s-3.582,8-8,8S16.057,348.418,16.057,344z"/>
                                    <path d="M32.057,432c-17.673,0-32,14.327-32,32c0,17.673,14.327,32,32,32s32-14.327,32-32C64.057,446.327,49.73,432,32.057,432z
                                        M32.057,480c-8.837,0-16-7.163-16-16s7.163-16,16-16s16,7.163,16,16S40.894,480,32.057,480z"/>
                                    <path d="M72.057,368c-13.255,0-24,10.745-24,24s10.745,24,24,24s24-10.745,24-24S85.312,368,72.057,368z M72.057,400
                                        c-4.418,0-8-3.582-8-8s3.582-8,8-8s8,3.582,8,8S76.475,400,72.057,400z"/>
                                    <path d="M144.057,392c-13.255,0-24,10.745-24,24s10.745,24,24,24s24-10.745,24-24S157.312,392,144.057,392z M144.057,424
                                        c-4.418,0-8-3.582-8-8s3.582-8,8-8s8,3.582,8,8S148.475,424,144.057,424z"/>
                                    <path d="M152.057,304c-17.673,0-32,14.327-32,32c0,17.673,14.327,32,32,32s32-14.327,32-32
                                        C184.057,318.327,169.73,304,152.057,304z M152.057,352c-8.837,0-16-7.163-16-16s7.163-16,16-16s16,7.163,16,16
                                        S160.894,352,152.057,352z"/>
                                    <rect x="80.057" y="432" width="16" height="16"/>
                                    <rect x="112.057" y="456" width="16" height="16"/>
                                </svg>
                            </span>
                            <span class="d-flex align-items-center pl-3">
                                <span class="d-block badges-title">Изпиране с Екстрактор</span>
                            </span>
                        </span>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col mb-5 mb-md-0">
                        <span class="d-flex bump"
                        @click="service3Active = true, service2Active = false, serviceActive = false, service4Active = false">
                            <span class="d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 510.909 510.909" style="enable-background:new 0 0 510.909 510.909;" xml:space="preserve" width="75" height="75">
                                    <path d="M475,100.486H121.478c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5,7.5,7.5H475c11.53,0,20.91,9.38,20.91,20.91v159.691   c0,11.53-9.38,20.91-20.91,20.91h-17.959v-48.867c0-4.143-3.358-7.5-7.5-7.5H61.368c-4.142,0-7.5,3.357-7.5,7.5v48.867H35.91   c-11.53,0-20.91-9.38-20.91-20.91V136.396c0-11.53,9.38-20.91,20.91-20.91h55.568c4.142,0,7.5-3.357,7.5-7.5s-3.358-7.5-7.5-7.5   H35.91c-19.801,0-35.91,16.109-35.91,35.91v159.691c0,19.801,16.109,35.91,35.91,35.91h78.071c4.142,0,7.5-3.357,7.5-7.5   c0-4.142-3.358-7.5-7.5-7.5H68.868v-13.184h298.061c4.142,0,7.5-3.357,7.5-7.5c0-4.143-3.358-7.5-7.5-7.5H68.868v-13.184h373.173   v13.184h-45.112c-4.142,0-7.5,3.357-7.5,7.5c0,4.143,3.358,7.5,7.5,7.5h45.112v13.184H143.98c-4.142,0-7.5,3.358-7.5,7.5   c0,4.143,3.358,7.5,7.5,7.5H475c19.801,0,35.91-16.109,35.91-35.91V136.396C510.909,116.596,494.8,100.486,475,100.486z"/>
                                    <path d="M449.541,161.467c4.142,0,7.5-3.357,7.5-7.5c0-4.143-3.358-7.5-7.5-7.5H427.36c-4.142,0-7.5,3.357-7.5,7.5   c0,4.143,3.358,7.5,7.5,7.5H449.541z"/>
                                    <path d="M393.139,161.467c4.142,0,7.5-3.357,7.5-7.5c0-4.143-3.358-7.5-7.5-7.5H251.183c-4.142,0-7.5,3.357-7.5,7.5   c0,4.143,3.358,7.5,7.5,7.5H393.139z"/>
                                    <path d="M141.082,352.064c-4.142,0-7.5,3.357-7.5,7.5c0,0.223-0.241,22.457-21.047,37.245c-3.376,2.399-4.168,7.082-1.768,10.458   c1.463,2.059,3.773,3.156,6.12,3.156c1.503,0,3.021-0.451,4.339-1.388c27.046-19.224,27.357-48.246,27.357-49.472   C148.582,355.421,145.224,352.064,141.082,352.064z"/>
                                    <path d="M194.783,352.064c-4.142,0-7.5,3.357-7.5,7.5v43.358c0,4.143,3.358,7.5,7.5,7.5c4.142,0,7.5-3.357,7.5-7.5v-43.358   C202.283,355.421,198.925,352.064,194.783,352.064z"/>
                                    <path d="M257.564,352.064c-4.142,0-7.5,3.357-7.5,7.5v43.358c0,4.143,3.358,7.5,7.5,7.5c4.142,0,7.5-3.357,7.5-7.5v-43.358   C265.064,355.421,261.706,352.064,257.564,352.064z"/>
                                    <path d="M398.375,396.809c-20.526-14.589-21.036-36.281-21.047-37.279c-0.018-4.127-3.369-7.466-7.5-7.466   c-4.142,0-7.5,3.357-7.5,7.5c0,1.226,0.311,30.248,27.357,49.472c1.318,0.937,2.836,1.388,4.339,1.388   c2.346-0.001,4.657-1.099,6.12-3.156C402.543,403.891,401.751,399.208,398.375,396.809z"/>
                                    <path d="M316.126,352.064c-4.142,0-7.5,3.357-7.5,7.5v43.358c0,4.143,3.358,7.5,7.5,7.5c4.142,0,7.5-3.357,7.5-7.5v-43.358   C323.626,355.421,320.268,352.064,316.126,352.064z"/>
                                </svg>
                            </span>
                            <span class="d-flex align-items-center pl-3">
                                <span class="d-block badges-title">Профилактика Климатици</span>
                            </span>
                        </span>
                    </div>
                    <div class="col mb-5 mb-md-0">
                        <span class="d-flex bump"
                        @click="service4Active = true, service2Active = false, service3Active = false, serviceActive = false">
                            <span class="d-flex align-items-center">
                                <svg height="75" width="75" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	                            viewBox="0 0 480 480" style="enable-background:new 0 0 480 480;" xml:space="preserve">
                                    <path d="M472,272h-8v-24c-0.021-15.886-9.44-30.254-24-36.608V88c-0.002-3.18-1.886-6.056-4.8-7.328
                                    c3.121-5.002,4.783-10.776,4.8-16.672c0-17.673-14.327-32-32-32c-17.673,0-32,14.327-32,32c0.033,5.634,1.569,11.157,4.448,16
                                    H99.552c2.879-4.843,4.415-10.366,4.448-16c0-17.673-14.327-32-32-32S40,46.327,40,64c0.017,5.896,1.679,11.67,4.8,16.672
                                    C41.886,81.944,40.002,84.82,40,88v123.392C25.44,217.746,16.021,232.114,16,248v24H8c-4.418,0-8,3.582-8,8v112
                                    c0,4.418,3.582,8,8,8h8v40c0,4.418,3.582,8,8,8h32c4.418,0,8-3.582,8-8v-40h352v40c0,4.418,3.582,8,8,8h32c4.418,0,8-3.582,8-8
                                    v-40h8c4.418,0,8-3.582,8-8V280C480,275.582,476.418,272,472,272z M408,48c8.837,0,16,7.163,16,16s-7.163,16-16,16
                                    s-16-7.163-16-16S399.163,48,408,48z M72,48c8.837,0,16,7.163,16,16s-7.163,16-16,16s-16-7.163-16-16S63.163,48,72,48z M56,96h368
                                    v112h-32.208c5.294-6.883,8.179-15.316,8.208-24v-16c-0.026-22.08-17.92-39.974-40-40h-64c-22.08,0.026-39.974,17.92-40,40v16
                                    c0.029,8.684,2.914,17.117,8.208,24h-48.416c5.294-6.883,8.179-15.316,8.208-24v-16c-0.026-22.08-17.92-39.974-40-40h-64
                                    c-22.08,0.026-39.974,17.92-40,40v16c0.029,8.684,2.914,17.117,8.208,24H56V96z M384,168v16c0,13.255-10.745,24-24,24h-64
                                    c-13.255,0-24-10.745-24-24v-16c0-13.255,10.745-24,24-24h64C373.255,144,384,154.745,384,168z M208,168v16
                                    c0,13.255-10.745,24-24,24h-64c-13.255,0-24-10.745-24-24v-16c0-13.255,10.745-24,24-24h64C197.255,144,208,154.745,208,168z
                                    M32,248c0-13.255,10.745-24,24-24h368c13.255,0,24,10.745,24,24v24H32V248z M48,432H32v-32h16V432z M448,432h-16v-32h16V432z
                                    M464,384H16v-96h448V384z"/>
                                    <path d="M72,352H40c-4.418,0-8,3.582-8,8s3.582,8,8,8h32c4.418,0,8-3.582,8-8S76.418,352,72,352z"/>
                                    <path d="M440,352H104c-4.418,0-8,3.582-8,8s3.582,8,8,8h336c4.418,0,8-3.582,8-8S444.418,352,440,352z"/>
                                </svg>
                            </span>
                            <span class="d-flex align-items-center pl-3">
                                <span class="d-block badges-title">Обезпрашаване</span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="results">
                <div class="container" v-show="serviceActive">
                    <div class="row">
                        <div class="col">
                            <div class="add-services flex flex-row mt-3">
                                <h3>ДЕЙНОСТИ</h3>
                                <ul class="one-col">
                                    <li>почистване на матраци с Рейнбоу машина</li>
                                    <li>почистване на мека мебел с Рейнбоу машина</li>
                                    <li>почистване на килими с Рейнбоу машина</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container" v-show="service2Active">
                    <div class="row">
                        <div class="col">
                            <div class="add-services flex flex-row mt-3">
                                <h3>ДЕЙНОСТИ</h3>
                                <ul class="one-col">
                                    <li>изпиране на матраци</li>
                                    <li>изпиране на дивани и мека мебел</li>
                                    <li>изпиране на килими</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container" v-show="service3Active">
                    <div class="row">
                        <div class="col">
                            <div class="add-services flex flex-row mt-3">
                                <h3>ДЕЙНОСТИ</h3>
                                <ul class="one-col">
                                    <li>външно обезпрашаване</li>
                                    <li>изпиране на филтри</li>
                                    <li>почистване с препарат против бактерии</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container" v-show="service4Active">
                    <div class="row">
                        <div class="col">
                            <div class="add-services flex flex-row mt-3">
                                <h3>ДЕЙНОСТИ</h3>
                                <ul class="one-col">
                                    <li>картини</li>
                                    <li>книги</li>
                                    <li>осветителни тела (без демонтаж)</li>
                                    <li>радиатори</li>
                                    <li>стени</li>
                                    <li>мебели, бюра и техника</li>
                                    <li>премахване на паяжини</li>
                                    <li>прахосмукиране</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ServiceForm :servicePrice="4"/>
    </div>
</template>
<script>
import ServiceForm from '../../../ServiceForm'

export default {
    name: "HomesHealthy",
    data() {
        return {
            serviceActive: false,
            service2Active: false,
            service3Active: false,
            service4Active: false,
        }
    },
    components: {
        ServiceForm,
    }
}
</script>